import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    library,
    IconLookup,
    IconDefinition,
    findIconDefinition
} from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { Scrollbars } from 'react-custom-scrollbars';

library.add(fab)

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    avatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
}));

const githubLookup: IconLookup = { prefix: 'fab', iconName: 'github' }
const githubIconDefinition: IconDefinition = findIconDefinition(githubLookup)
const twitterLookup: IconLookup = { prefix: 'fab', iconName: 'twitter' }
const twitterIconDefinition: IconDefinition = findIconDefinition(twitterLookup)
const facebookLookup: IconLookup = { prefix: 'fab', iconName: 'facebook' }
const facebookIconDefinition: IconDefinition = findIconDefinition(facebookLookup)

const App = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Container component="main" className={classes.main} maxWidth="sm">
                <Avatar alt="kosa3" src="/kosa.png" className={classes.avatar} />

                <Typography variant="h4" component="h2" gutterBottom>
                    Web Developer
                </Typography>
                <Scrollbars
                    style={{ height: 300 }}>
                    <h2># Name</h2>
                    <h3>kosa3</h3>
                    <h2># Skills</h2>
                    <ul>
                        <li>Frontend</li>
                        <ul>
                            <li>React</li>
                            <li>Vue</li>
                            <li>jQuery</li>
                        </ul>
                        <li>Backend</li>
                        <ul>
                            <li>PHP</li>
                            <li>Go</li>
                            <li>Node</li>
                            <li>Python</li>
                        </ul>
                        <li>Framework</li>
                        <ul>
                            <li>Laravel</li>
                            <li>Codeigniter</li>
                            <li>ZendFramework</li>
                            <li>Gin</li>
                            <li>Express</li>
                            <li>Django</li>
                        </ul>
                        <li>Infrastructures</li>
                        <ul>
                            <li>AWS</li>
                            <li>GCP</li>
                            <li>Docker</li>
                            <li>CI / CD</li>
                            <li>ElasticSearch</li>
                        </ul>
                    </ul>
                    <h2># Twitter</h2>
                    <h3>
                        <a href="https://twitter.com/kosachan3" target={'_blank'} rel="noreferrer">@kosachan3</a>
                    </h3>
                    <h2># Github</h2>
                    <h3>
                        <a href="https://github.com/kosa3" target={'_blank'} rel="noreferrer">kosa3</a>
                    </h3>
                    <h2># Blog</h2>
                    <h3>
                        <a href="https://medium.com/@kosa3" target={'_blank'} rel="noreferrer">Medium</a>
                    </h3>
                    <h2># RECENT ACTIVITIES</h2>
                    <ul>
                        <li>2018/12/15 PHP Conference 2018: <a href="https://speakerdeck.com/kosa3/jin-karademojian-nihe-uphp5-dot-5karaphp7-dot-2nibaziyonatupusitahua-number-phpcon" target={'_blank'} rel="noreferrer">今からでも間に合うphp5.5からphp7.2にバージョンアップした話</a></li>
                        <li>2019/03/30 PHPerKaigi2019: <a href="https://speakerdeck.com/kosa3/3keyue-dephp5-dot-5kara7-dot-2nibaziyonatupusitaxian-zai-tojin-hou-falsexiang-kihe-ifang-number-phperkaigi-2019" target={'_blank'} rel="noreferrer">3ヶ月でphp5.5から7.2にバージョンアップした現在と今後の向き合い方 </a></li>
                    </ul>
                </Scrollbars>
            </Container>

            <footer className={classes.footer}>
                <Typography variant="h4" align="center" gutterBottom>
                    <Link color="inherit" href="https://github.com/kosa3" target={'_blank'}>
                        <FontAwesomeIcon className={classes.icon} icon={githubIconDefinition} />
                    </Link>
                    <Link color="inherit" href="https://twitter.com/kosachan3" target={'_blank'}>
                        <FontAwesomeIcon className={classes.icon} icon={twitterIconDefinition} />
                    </Link>
                    <Link color="inherit" href="https://www.facebook.com/profile.php?id=100008359952421" target={'_blank'}>
                        <FontAwesomeIcon className={classes.icon} icon={facebookIconDefinition} />
                    </Link>
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    © kosa3
                </Typography>
            </footer>
        </div>
    );
}

export default App;
